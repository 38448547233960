html {
	box-sizing: border-box;
	font-size: 16px;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

html,
body {
	font-family: "Gotham Pro";
	height: 100%;
	margin: 0;
	padding: 0;
	position: relative;
}

.root {
	background: url('/img/fon.webp') #FCFCFC no-repeat;
	background-size: cover;
}

.katestan {
	background: url('/img/estankevich.webp') no-repeat top 50px right 20%;
	background-size: contain;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

button:focus,
input:focus {
	outline: transparent;
}

.show {
	display: block;
}

.hide {
	display: none;
	transition: opacity .15s linear;
}

.fade {
	animation-name: fade;
	animation-duration: 1.5s;
}

@keyframes fade{from{opacity: 0.1;}to{opacity: 1;}}

.line {
    border-top: 2px dashed #C4C4C4;
	margin: 5px 0;
}