

@font-face {
	font-family: 'Gotham Pro';
	src: url('/fonts/GothamPro-Medium.eot');
	src: local('Gotham Pro Medium'), local('/fonts/GothamPro-Medium'),
		url('/fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GothamPro-Medium.woff') format('woff'),
		url('/fonts/GothamPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}


@font-face {
	font-family: 'Gotham Pro';
	src: url('/fonts/GothamPro-Bold.eot');
	src: local('Gotham Pro Bold'), local('/fonts/GothamPro-Bold'),
		url('/fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GothamPro-Bold.woff') format('woff'),
		url('/fonts/GothamPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro Narrow';
	src: url('/fonts/GothamProNarrow-Bold.eot');
	src: local('Gotham Pro Narrow Bold'), local('/fonts/GothamProNarrow-Bold'),
		url('/fonts/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GothamProNarrow-Bold.woff') format('woff'),
		url('/fonts/GothamProNarrow-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('/fonts/GothamPro-Black.eot');
	src: local('Gotham Pro Black'), local('/fonts/GothamPro-Black'),
		url('/fonts/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GothamPro-Black.woff') format('woff'),
		url('/fonts/GothamPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('/fonts/GothamPro.eot');
	src: local('Gotham Pro'), local('/fonts/GothamPro'),
		url('/fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
		url('/fonts/GothamPro.woff') format('woff'),
		url('/fonts/GothamPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:29 AM */

@font-face {
	font-family: 'BebasNeue';
	src: url('/fonts/BebasNeueRegular.eot');
	src: local('Bebas Neue Regular'), local('BebasNeueRegular'),
		url('/fonts/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
		url('/fonts/BebasNeueRegular.woff') format('woff'),
		url('/fonts/BebasNeueRegular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'BebasNeue';
	src: url('/fonts/BebasNeueBold.eot');
	src: local('Bebas Neue Bold'), local('BebasNeueBold'),
		url('/fonts/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
		url('/fonts/BebasNeueBold.woff') format('woff'),
		url('/fonts/BebasNeueBold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'BebasNeue';
	src: url('/fonts/BebasNeueLight.eot');
	src: local('Bebas Neue Light'), local('BebasNeueLight'),
		url('/fonts/BebasNeueLight.eot?#iefix') format('embedded-opentype'),
		url('/fonts/BebasNeueLight.woff') format('woff'),
		url('/fonts/BebasNeueLight.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'BebasNeue';
	src: url('/fonts/BebasNeue-Thin.eot');
	src: local('Bebas Neue Thin'), local('BebasNeue-Thin'),
		url('/fonts/BebasNeue-Thin.eot?#iefix') format('embedded-opentype'),
		url('/fonts/BebasNeue-Thin.woff') format('woff'),
		url('/fonts/BebasNeue-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'BebasNeue';
	src: url('/fonts/BebasNeueBook.eot');
	src: local('Bebas Neue Book'), local('BebasNeueBook'),
		url('/fonts/BebasNeueBook.eot?#iefix') format('embedded-opentype'),
		url('/fonts/BebasNeueBook.woff') format('woff'),
		url('/fonts/BebasNeueBook.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}



