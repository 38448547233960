.header {
    padding-top: 59px;
    &__block {
        display: inline-grid;
    }
    &__content {
        display: flex;
        align-items: center;
    }
    &__today {
        font-family: "Gotham Pro";
        font-size: 16px;
        color: #fff;
        text-transform: uppercase;
        background: #E11993;
        padding: 5px 5px 4px 5px;
        border-radius: 3px;
        margin-bottom: 18px;
    }
    &__online {
        font-size: 44px;
        font-family: "BebasNeue";
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        background-image: linear-gradient(to bottom, #0e98c7 0%, #51d1f3 99%, #51d1f3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all .2s;
    }
    &__time {
        text-transform: uppercase;
        font-family: "BebasNeue";
        color: #393D3E;
        font-size: 54px;
        margin-left: 22px;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.07em;
    }
    &__name {
        background-image: linear-gradient(to bottom, #0e98c7 0%, #51d1f3 99%, #51d1f3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: all .2s;
        font-family: "BebasNeue";
        font-size: 48px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 48px;
        text-align: right;
    }
}


.main {
    margin-top: 4%;
    &__lead {
        color: #000000;
        font-family: "BebasNeue";
        font-size: 63px;
        font-weight: bold;
        font-style: normal;
        text-transform: uppercase;
        line-height: 56.32px;
    }
    &__images {
        display: none;
        width: 100%;
    }
    &__subtitle {
        background-image: linear-gradient(to bottom, #0e98c7 0%, #51d1f3 99%, #51d1f3 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "BebasNeue";
        text-transform: uppercase;
        font-size: 63px;
        font-weight: bold;
        font-style: normal;
        line-height: 56.32px;
    }
    &__title {
        color: #e11993;
        font-family: "BebasNeue";
        font-size: 71px;
        font-weight: bold;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: normal;
        line-height: 66.29px;
        margin-top: 7px;
        line-height: 130.3%;
    }
    &__contain {
        margin-top: 28px;
        ul {
            padding: 0 20px;
            line-height: 24px;
        }
        li {
            color: #e11993;

            span {
                color:#000000;
            }
           }
    }
    &__slogan {
        font-family: "Gotham Pro";
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        line-height: 34px;
    }
    &__container {
        position: relative;
        display: block;
    }
    &__button {
        margin-top: 5%;
        position: relative;
        display: inline-block;
        text-decoration: none;
        background: #EE51B1;
        font-family: "BebasNeue";
        position: relative;
        cursor: pointer;
        color: #fff;
        padding: 30px 39px 24px 39px;
        border: none;
        border-radius: 50px;
        font-size: 29px;
        font-weight: 400;
        line-height: 22px;
        text-transform: uppercase;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 1.5em;
            height: 100%;
            filter: blur(40px);
            background-color: rgba(#fff, 0.8);
            transform: translateX(-8em) skew(-45deg);
            animation: identifier 1.8s infinite;
        }
    }

    @keyframes identifier {
        from {
            transform: translateX(-8em) skew(-45deg);
        }
        to {
            transform: translateX(13em) skew(-45deg);
        }
    }

    &__present {
        background: url('/img/present.png') no-repeat;
        position: absolute;
        top: -12px;
        right: 60px;
        width: 60px;
        height: 60px;
    }
    &__descr {
        text-align: right;
        font-family: "Gotham Pro";
        font-weight: 400;
        line-height: 19px;
        line-height: 24px;
        margin-top: -30%;
        font-size: 14px;
    }
}

.footer {
    padding: 41px;
    &__block {
        display: block;
        line-height: 25px;
    }
    &__autor {
        font-size: 20px;
        display: block;
        font-family: "Gotham Pro";
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
    }
    &__kanal {
        padding: 0 10px;
    }
    &__images {
        width: 100%;
        box-shadow: 0 11px 58px 7px rgba(0, 0, 0, 0.22);
        border-radius: 6px;
        border: 2px solid #d0d0d0;
        background-color: #ffffff;
    }
    &__video {
        box-shadow: 0 11px 58px 7px rgba(0, 0, 0, 0.22);
        border-radius: 6px;
        border: 2px solid #d0d0d0;
        background-color: #ffffff;
        cursor: pointer;
        width: 100%;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    width: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(0, 0, 0, 0.7);
	overflow-x: hidden;
    overflow-y: auto;
	height: 100%;

    &__dialog {
        max-width: 400px;
        height: 100%;
        margin: 15% auto;    
    }
    &__content {
        background: #FFFFFF;
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.01);
        border-radius: 10px;
        padding:30px 28px;
        text-align: center;
        position: relative;
    }
    &__close {
        position: absolute;
        background: none;
        font-size: 18px;
        cursor: pointer;
        border: none;
        right: 20px;
        top: 20px;
    }
    &__title {
        font-family: "Gotham Pro";
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 130.3%;
        color: #000000;
    }
    &__text {
        margin: 5px 0px 10px 0px;
        font-size: 14px;
    }
    &__input {
        width: 100%;
        padding: 15px;
        background: #F8F8F8;
        border: 1px solid #F2F2F2;
        box-sizing: border-box;
        border-radius: 7px;
        margin: 5px 0;
        font-size: 14px;

        &:focus {
            background: #FFFFFF;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.05);
            border-radius: 7px;
        }
    }
    &__button {
        margin-top: 10px;
        background: url('/img/btn.png') #EE51B1;
        font-family: "BebasNeue";
        cursor: pointer;
        color: #fff;
        padding: 15px 0 10px 0;
        width: 100%;
        border: none;
        border-radius: 7px;
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        text-transform: uppercase;
    }
    &__span {
        color: #999999;
        font-size: 12px;
    }
}

.btn-group-toggle {
    margin-top: 15px;
}

.modalThank {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    width: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(0, 0, 0, 0.7);
	overflow-x: hidden;
    overflow-y: auto;
	height: 100%;

    &__dialog {
        max-width: 400px;
        height: 100%;
        margin: 15% auto;    
    }

    &__body {
        padding: 20px 0;
    }

    &__tab {
        font-weight: normal;
        font-size: 14px;
        color: #000;
        padding: 12px 60px;
        background: rgba(70, 200, 236, 0.5);
        border: none;
        box-sizing: border-box;
        border-radius: 5px;
        box-sizing: border-box;
        border-radius: 5px;
        cursor: pointer;
    }
    &__info {
        padding: 20px 0;
        font-weight: 500;
        font-size: 34px;
        line-height: 130.3%;
        color: #000000;
    }
    &__btn {
        background: linear-gradient(0deg, #EE51B1 0%, #E11993 100%), #C4C4C4;
        border-radius: 7px;
        text-transform: uppercase;
        border: none;
        color: #fff;
        padding: 16px 48px;
    }
}

.thank {
    &__content {
        box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.01);
        border-radius: 10px;
        padding:30px 28px;
        text-align: center;
        position: relative;
    }
    &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 130.3%;
        text-align: center;

        span {
            background: #E11993;
            font-weight: normal;
            color: #fff;
            padding: 5px;
        }
    }
}

.tabactive {
    background: #B6ED67;
    color: #000;
}

.lt-block-wrapper {
    padding: 0 !important;
    margin: 0 !important;
}

.roots {
	background: url('/img/fon.webp') #FCFCFC no-repeat;
	background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.slider {
    display: none;
}
.slick-loading .slick-list
{
    background: none !important;
}