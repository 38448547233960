// Устройства X-Small (портретные телефоны, менее 576 пикселей)
@media (max-width: 575.98px) {
    .katestan {
        background: none !important;
    }
    .header {
        padding-top: 40px;
        &__name {
            margin-top: 40px;
            text-align: left;
        }
        &__time {
            font-size: 42px;
        }
    }
    .main {
        &__images {
            display: block;
        }
        &__slogan {
            font-size: 16px;
        }
        &__contain {
            z-index: 999;
        }
        &__button {
            width: 100%;
            font-size: 24px;
        }
        &__lead, &__subtitle {
            font-size: 40px;
            line-height: 42.09px;
            font-weight: bold;
        }
        &__title {
            font-size: 43px;
            font-weight: bold;
        }
        &__present {
            background: url('/img/present.png') no-repeat;
            position: absolute;
            top: -12px;
            right: 0;
            width: 60px;
            height: 60px;
        }
    }
    .footer {
        padding: 0;

        &__video {
            width: 100% !important;
        }
    }
    .thank {
        &__title {
            font-size: 22px;
        }
    }
    .modalThank {
        &__body {
            display: flex;
            justify-content: space-between;
        }
        &__tab {
            padding: 10px 15px;
        }
        &__info {
            font-size: 20px;
            padding: 10px 0;
        }
        &__btn {
            width: 100%;
            margin: 5px 0;
            font-size: 14px;
            padding: 14px 24px;
        }
    }
}

@media (max-width: 767.98px) {
    .katestan {
        background: none !important;
    }
    .main {
        &__present {
            background: url('/img/present.png') no-repeat;
            position: absolute;
            top: -12px;
            right: 0;
            width: 80px;
            height: 80px;
        }
        &__button {
            width: 100%;
        }
    }
    .footer {
        padding: 0;

        &__video {
            width: 100% !important;
        }
    }
    .thank {
        &__title {
            font-size: 22px;
        }
    }
    .modalThank {
        &__body {
            display: flex;
            justify-content: space-between;
        }
        &__tab {
            padding: 10px 15px;
        }
        &__info {
            font-size: 20px;
            padding: 10px 0;
        }
        &__btn {
            width: 100%;
            margin: 5px 0;
            font-size: 14px;
            padding: 14px 24px;
        }
    }
}

@media (max-width: 991.98px) {
    .katestan {
        background: none !important;
    }
    .header {
        &__name {
            text-align: left;
            margin-top: 25px;
        }
    }
    .main {
        &__present {
            background: url('/img/present.png') no-repeat;
            position: absolute;
            top: -12px;
            right: 80px;
            width: 80px;
            height: 80px;
        }
    }
    .line {
        border-top: 1px dashed #C4C4C4;
        margin: 15px 0;
    }
    .footer {
        padding: 0;

        &__video {
            width: 100% !important;
        }
    }
    .thank {
        &__title {
            font-size: 22px;
        }
    }
    .modalThank {
        &__body {
            display: flex;
            justify-content: space-between;
        }
        &__tab {
            padding: 10px 15px;
        }
        &__info {
            font-size: 20px;
            padding: 10px 0;
        }
        &__btn {
            width: 100%;
            margin: 5px 0;
            font-size: 14px;
            padding: 14px 24px;
        }
    }
}



@media (max-width: 1399.98px) {
    .root {
        background: url('/img/fon.webp') #FCFCFC no-repeat;
        background-size: cover;
    }
    .katestan {
        background: url('/img/estankevich.webp') no-repeat top 50px right 20%;
        background-size: contain;
    }
    .main {
        &__present {
            background: url('/img/present.png') no-repeat;
            position: absolute;
            top: -12px;
            right: 0;
            width: 80px;
            height: 80px;
        }
        &__button {
            width: 100%;
        }
    }
}